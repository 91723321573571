<template>
  <block
    component-name="block-text-paragraph-videoplayer"
    body-class="relative top-0 left-0 grid grid-cols-12 gap-y-4xl"
    data-skin="sand"
  >
    <span
      class="row-start-1 row-end-4 col-span-full bg-skin-subtle -mx-edge"
    ></span>

    <div
      class="w-full row-start-1 row-end-3 col-span-full tablet:col-start-2 tablet:col-end-12 pt-5xl"
    >
      <wrapper-split-text
        :reveal="$attrs.config?.headingLevel === 'hero' ? 'chars' : 'words'"
      >
        <div
          v-html="$attrs.data.body.title"
          class="w-full"
          :class="{
            'text-display-hero': $attrs.config?.headingLevel === 'hero',
            'text-heading-1': $attrs.config?.headingLevel === '1',
            'text-heading-2': $attrs.config?.headingLevel === '2',
            'text-heading-3':
              $attrs.config?.headingLevel === '3' ||
              !$attrs.config?.headingLevel,
            'text-heading-4': $attrs.config?.headingLevel === '4',
          }"
        ></div>
      </wrapper-split-text>
    </div>

    <wrapper-reveal
      reveal="unveil-from-right"
      class="w-full row-start-3 row-end-5 col-span-full phablet:col-start-2 phablet:col-end-12 tablet:col-start-3 tablet:col-end-11"
    >
      <videoplayer
        :useYoutube="$attrs.config.useYotube || false"
        :video-id="$attrs.data.body.video.videoId || undefined"
        :use-loop="true"
        :title="$attrs.data.body.video.title || undefined"
        :cdn="$attrs.data.body.video.cdn || undefined"
        :src="$attrs.data.body.video.src || undefined"
        :poster="$attrs.data.body.video.poster || undefined"
        :width="$attrs.data.body.video.width || undefined"
        :height="$attrs.data.body.video.height || undefined"
        :aspect-ratio="$attrs.data.body.video.aspectRatio || undefined"
        :pretty-name="$attrs.data.body.video.pretty_name || undefined"
        :dominant-color="$attrs.data.body.video.dominant_color || undefined"
        class="w-full"
      />
    </wrapper-reveal>
  </block>
</template>
